<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-row :gutter='24'>
      <a-col :sm='12' :md='4' :xl='4' :style="{ marginBottom: '24px' }">
        <a-card>
          <div class="card_title">实时商品折扣：{{dashboard.wealthBtcfQuotaAmount + dashboard.wealthQuotaAmount + dashboard.wealthZxQuotaAmount}}</div>
          <div class="card_title" style="line-height: 30px;">31：{{dashboard.wealthBtcfQuotaAmount}}</div>
          <div class="card_title" style="line-height: 30px;">41：{{dashboard.wealthQuotaAmount}}</div>
          <div class="card_title" style="line-height: 30px;">51：{{dashboard.wealthZxQuotaAmount}}</div>
        </a-card>
      </a-col>

      <a-col :sm='12' :md='4' :xl='4' :style="{ marginBottom: '24px' }">
        <a-card>
          <div class="card_title">本周获得：{{dashboard.weekGetTotal}}</div>
          <div class="card_title" style="line-height: 30px;">31：{{dashboard.getThree}}</div>
          <div class="card_title" style="line-height: 30px;">41：{{dashboard.getFour}}</div>
          <div class="card_title" style="line-height: 30px;">51：{{dashboard.getFive}}</div>
        </a-card>
      </a-col>

      <a-col :sm='12' :md='4' :xl='4' :style="{ marginBottom: '24px' }">
        <a-card>
          <div class="card_title">本周使用：{{dashboard.weekUseTotal}}</div>
          <div class="card_title" style="line-height: 30px;">31：{{dashboard.useThree}}</div>
          <div class="card_title" style="line-height: 30px;">41：{{dashboard.useFour}}</div>
          <div class="card_title" style="line-height: 30px;">51：{{dashboard.useFive}}</div>
        </a-card>
      </a-col>

    </a-row>
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='quotaType'>
        <a-select v-model="form.quotaType" placeholder="名额类型" style="width: 250px">
          <a-select-option :value="undefined">
            名额类型
          </a-select-option>
          <a-select-option :value="3">
            31
          </a-select-option>
          <a-select-option :value="1">
            41
          </a-select-option>
          <a-select-option :value="2">
            51
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- <a-form-model-item prop='memberType'>
        <a-select v-model="form.memberType" placeholder="用户类型" style="width: 250px">
          <a-select-option :value="undefined">
            用户类型
          </a-select-option>
          <a-select-option :value="2">
            商户
          </a-select-option>
          <a-select-option :value="1">
            用户
          </a-select-option>
        </a-select>
      </a-form-model-item> -->
      <a-form-model-item prop='endTag'>
        <a-select v-model="form.endTag" placeholder="订单尾号" style="width: 250px">
          <a-select-option value="-1">
            全部
          </a-select-option>
          <a-select-option v-for="i in 7" :key="i" :value="i">
            {{i}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='keyword'>
        <a-input v-model='form.keyword' style="width: 300px" placeholder="姓名/手机号" />
      </a-form-model-item>

      <a-form-model-item prop='memberType'>
        <a-select v-model="form.memberType" placeholder="身份类型" style="width: 250px">
          <a-select-option value="2">
            全部会员
          </a-select-option>
          <a-select-option value="1">
            会员
          </a-select-option>
          <a-select-option value="3">
            云店
          </a-select-option>
          <a-select-option value="4">
            服务中心
          </a-select-option>
          <a-select-option value="5">
            运营总裁
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop='type'>
        <a-select v-model="form.type" placeholder="变动类型" style="width: 250px">
          <a-select-option :value="undefined">
            变动类型
          </a-select-option>
<!--          <a-select-option :value="1">-->
<!--            合成-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="2">-->
<!--            使用-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="3">-->
<!--            修改-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="5">-->
<!--            累计值-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="7">-->
<!--            取删返还-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="8">-->
<!--            作废-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="9">-->
<!--            店面补贴-->
<!--          </a-select-option>-->
          <a-select-option :value="1">
            使用
          </a-select-option>
          <a-select-option :value="2">
            修改
          </a-select-option>
          <a-select-option :value="3">
            奖励
          </a-select-option>
          <a-select-option :value="4">
            兑换
          </a-select-option>
          <a-select-option :value="5">
            转让
          </a-select-option>
          <a-select-option :value="6">
            受让
          </a-select-option>
          <a-select-option :value="7">
            退回
          </a-select-option>
          <a-select-option :value="8">
            活动奖励
          </a-select-option>
          <a-select-option :value="9">
            房租补贴
          </a-select-option>
          <a-select-option :value="10">
            门票
          </a-select-option>
          <a-select-option :value="13">
            商户删除
          </a-select-option>
          <a-select-option :value="14">
            获得
          </a-select-option>
          <a-select-option :value="15">
            推广
          </a-select-option>
          <a-select-option :value="16">
            推广+活动
          </a-select-option>
          <a-select-option :value="19">
            奖励积分
          </a-select-option>
          <a-select-option :value="21">
            批量兑换
          </a-select-option>
          <a-select-option :value="22">
            批量兑换退回
          </a-select-option>
          <a-select-option :value="23">
            解除合同
          </a-select-option>
          <a-select-option :value="26">
            业绩5%
          </a-select-option>
          <a-select-option :value="17">
            业绩2%
          </a-select-option>
          <a-select-option :value="24">
            业绩1.5%
          </a-select-option>
          <a-select-option :value="25">
            业绩1-1%
          </a-select-option>
          <a-select-option :value="27">
            业绩1%
          </a-select-option>
          <a-select-option :value="28">
            业绩10%
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item> -->
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
        <span style="margin-left: 10px;margin-right: 10px;"> 笔数：{{this.total}}</span>
        <span> 变动值：{{this.ext4}}</span>
      </div>

      <s-table id="table" ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData'>
        <span slot='quotaType' slot-scope='text'>
          <template>
            {{ text == 1 ? "41" : text == 2 ? "51" : text == 3 ? "31" :  "" }}
          </template>
        </span>
        <span slot='memberType' slot-scope='text'>
          <template>
            {{ getUserTypeName(text) }}
          </template>
        </span>
        <span slot='orderId' slot-scope='text, record'>
          <template>
          {{record.business===3||record.business===9?record.opUsername:record.orderId}}
          </template>
        </span>
      </s-table>
    </div>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import { getAuditOrderData, queryDiscount } from '@/api/member-order'
import moment from 'moment'
import { getUserTypeName } from '@/utils/util'


const columns = [
  {
    width: 100,
    title: '名额类型',
    dataIndex: 'quotaType',
    scopedSlots: { customRender: 'quotaType' }
  },
  {
    width: 100,
    title: '变动类型',
    dataIndex: 'businessName',
    scopedSlots: { customRender: 'businessName' }
  },
  {
    width: 120,
    title: '变动值',
    dataIndex: 'businessContent',
    scopedSlots: { customRender: 'businessContent' }
  },
  {
    width: 100,
    title: '变动后数值',
    dataIndex: 'afterAmount',
    scopedSlots: { customRender: 'afterAmount' }
  },
  {
    width: 100,
    title: '身份类型',
    dataIndex: 'memberType',
    scopedSlots: { customRender: 'memberType' }
  },
  {
    width: 200,
    title: '发生订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 200,
    title: '时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
    {
    width: 180,
    title: '备注',
    dataIndex: 'orderTypeName',
    scopedSlots: { customRender: 'orderTypeName' }
  },
    {
    width: 150,
    title: '姓名',
    dataIndex: 'userName',
    scopedSlots: { customRender: 'userName' }
  },
  {
    width: 150,
    title: '手机号',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
]

const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'AuditMonthDiscountList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      // 筛选表单
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        keyword: "",
        // start: moment(baseStart).startOf('day').format(formatType + " HH:mm"),
        // end: moment(baseEnd).endOf('day').format(formatType+ " HH:mm"),
      },
      loading: true,
      dashboard: {}, //订单统计数据
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      queryParam: {},
      total:0,
      ext4:0,
      loadData: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        return queryDiscount(Object.assign(parameter, this.queryParam, this.form))
          .then(datum => {
            this.total = datum.total;
            this.ext4 = datum.ext4;
            return datum
          })
      },
    }
  },
  created() {
    this.getOrderData();
  },
  methods: {
    moment,
    getUserTypeName,
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.form.start =  moment(this.dateArr[0]).startOf('day').format(this.formatType + " HH:mm");
      this.form.end =  moment(this.dateArr[1]).endOf('day').format(this.formatType+ " HH:mm");
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = moment(baseStart).startOf('day').format(this.formatType + " HH:mm");
      this.form.end = moment(baseEnd).endOf('day').format(this.formatType + " HH:mm");
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      if(this.form.endTag === "-1"){
        delete this.form.endTag;
      }
      this.getOrderData();
      this.$refs.table.refresh(true)
    },
    getOrderData() {
      getAuditOrderData(Object.assign({}, this.form)).then(result => {
        result.wealthQuotaAmount = result.wealthQuotaAmount || 0,
        result.wealthZxQuotaAmount = result.wealthZxQuotaAmount || 0,
        result.wealthBtcfQuotaAmount = result.wealthBtcfQuotaAmount || 0,
        result.totalQuotaAmount = result.wealthQuotaAmount + result.wealthZxQuotaAmount + result.wealthBtcfQuotaAmount;
        result.totalQuotaAmount = parseFloat(result.totalQuotaAmount.toFixed(2));
        result.storeCash = result.storeCash || 0,
        result.centerCash = result.centerCash || 0,
        result.presidentCash = result.presidentCash || 0,
        result.totalCash = result.storeCash + result.centerCash + result.presidentCash;
        result.totalCash = parseFloat(result.totalCash.toFixed(2));

        result.getThree =  result.lastWeekWealthBtcfQuotaAmount||0;
        result.getFour = result.lastWeekWealthQuotaAmount||0;
        result.getFive = result.lastWeekWealthZxQuotaAmount||0;

        result.weekGetTotal = result.getThree+result.getFour+result.getFive;

        result.useThree =  result.lastUsedWeekWealthBtcfQuotaAmount||0;
        result.useFour = result.lastUsedWeekWealthQuotaAmount||0;
        result.useFive = result.lastUsedWeekWealthZxQuotaAmount||0;

        result.weekUseTotal = result.useThree+result.useFour+result.useFive;

        this.dashboard = result;
        this.loading = false;
      })
    },
  }
}
</script>
<style lang="less" scoped>
/deep/.order_count .ant-card-body {
  padding: 0 24px 3px !important;
  display: flex;
  flex-wrap: wrap;
}
.card_title {
    width: 100%;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
}
.card_content {
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  margin-top: 4px;
  color: #000;
}
.order_count .card_content {
  width: 50%;
}
</style>
